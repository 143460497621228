.isoImgWrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center
}

.userName {
    margin-bottom: 4px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}
